<template>
  <div class="language-picker" @click="toggle">
    <img :src="getImgUrl(`lang/${currentLocale}.png`)" class="flag" />
    <span>{{ $t('code') }}</span>
  </div>
</template>

<script>

export default {
  name: "LanguagePicker",
  data() {
    return {
      locales: [
        {name: 'Русский', key: 'ru'},
        {name: 'Узбекча', key: 'uz'}
      ]
    }
  },
  methods: {
    toggle() {
      const locale = this.currentLocale === 'ru' ? 'uz' : 'ru'
      this.$locale.change(locale)
    },
    getImgUrl(path) {
      return require(`../../assets/${path}`)
    }
  },
  computed: {
    currentLocale() {
      return this.$locale.current()
    }
  }
}
</script>