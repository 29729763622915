export default {
  lang: 'Русский',
  code: 'RU',
  k: 'Ru',
  interface: {
    back: 'Назад',
    next: 'Вперед',
    pagination_summary: 'Вы посмотрели {0} результатов из {1}',
    feedback: '+998555003355',
    footer: '© 2023 — NanoTech Computers LTD. Все права защищены.',
    search: 'Поиск',
    catalogue_categories: 'Категории каталога',
    logout: 'Выйти',
  },
  routes: {
    home: 'Главная',
    favorites: 'Избранное',
    search: 'Результаты поиска',
    sitemap: 'Карта сайта',
  },
  product: {
    out_of_stock: 'Нет в наличии',
    details: 'Подробнее',
    c_sum: 'сум',
    specifications: 'Характеристики',
  },
  search: {
    in_stock: 'В наличии',
    available: 'Доступно',
    all: 'Все',
    all_results: 'Все результаты по запросу «{0}»'
  },
  home: {
    all_categories: 'Все Категории'
  },
  category_detail: {
    no_result: 'Товары в данной категории не найдены.'
  },
  favorites: {
    no_result: 'Вы еще не добавили ни одного товара в избранное.'
  },
  search_results: {
    title: 'Результаты поиска по запросу «{0}»',
    no_result: 'По вашему запросу ничего не найдено'
  },
  not_found: {
    note: 'Вы попали на страницу, которой не существует, либо зашли по неверному адресу.<br>Не переживайте — попробуйте начать сначала.'
  }
};