var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.away),expression:"away"}],ref:"dropdown",staticClass:"dropdown-wrapper",class:{'is-active': _vm.isDropdownActive}},[_c('div',{staticClass:"dropdown-trigger",class:[_vm.triggerClass],attrs:{"aria-haspopup":"true"},on:{"click":_vm.toggle}},[_vm._t("trigger")],2),_c('transition',{attrs:{"name":"fade-fast"}},[(_vm.isDropdownActive)?_c('div',{staticClass:"dropdow-content",class:[_vm.contentClass, _vm.position],style:({
					top: `${_vm.menuBounding.top + _vm.menuBounding.height + parseInt(_vm.topOffset)}px`,
					left: _vm.position === 'is-left' && `${_vm.menuBounding.left}px`,
					left: _vm.position === 'is-right' && `${_vm.menuBounding.right}px`,
					width: _vm.fitWidth ? `${_vm.menuBounding.width}px` : 'min-content',
					zIndex: _vm.zIndex + 999
				})},[_vm._t("default")],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }