import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ru from './locales/ru';
import uz from './locales/uz';

Vue.use(VueI18n);

const messages = { ru, uz };

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'ru',
  messages,
});

Vue.prototype.$locale = {
  change(locale) {
    i18n.locale = locale;
    localStorage.setItem('locale', locale)
  },
  current() {
    return i18n.locale;
  },
};

export default i18n;
