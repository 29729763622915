export function numberWithSeparator(x, s) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
}

/**
 * Debounce decorator
 * @param {Function} fn - Function
 * @param {Number} cd - Cooldown in ms
 * @returns {Function} Debounced function
 */
 export function debounce(fn, cd) {
  let lastCall = 0
  return function () {
    let now = Date.now()
    if ((now - cd) >= lastCall) {
      lastCall = now
      fn.apply(this, arguments)
    }
  }
}