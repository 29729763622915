<template>
  <a :href="link" @click.prevent="push" v-bind="$attrs">
    <slot/>
  </a>
</template>

<script>
export default {
  name: 'Link',
  props: {
    path: null //should be object or false
  },
  methods: {
    push() {
      if(this.path) 
        this.$router.push(this.path)
    }
  },
  computed: {
    link() {
      return this.path ? this.$router.resolve(this.path).href : false;
    }
  }
}
</script>