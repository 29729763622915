import Vue from "vue"

const deepSearch = (categories, id) => {
  for (const category of categories) {
    if (category.id === id)
      return category
    else if (category.children) {
      let result = deepSearch(category.children, id)
      if (result) return result
    }
  }
}

export default {
  namespaced: true,
  state: {
    categories: [],
    productFilterType: 0
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_PRODUCT_FILTER_TYPE(state, type) {
      state.productFilterType = type
    }
  },
  getters: {
    getCategoryById: (state) => (id) => {
      return deepSearch(state.categories, id)
    },
    getMainPageCategories(state) {
      return state.categories.filter(category => category.joinMainPage)
    }
  },
  actions: {
    loadCategories({commit}) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('/api/v1/public/category/all', {
          __silent: true
        }).then((response) => {
          commit('SET_CATEGORIES', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
  },
}