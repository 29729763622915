import Vue from "vue"

export default {
  namespaced: true,
  state: {
    productsByCategory: [],
    favorites: [],
    favoriteProducts: [],
    productDetailId: null,
    productDetail: {},
    searchSuggestions: [],
    searchResults: []
  },
  mutations: {
    CLEAR_PRODUCTS_BY_CATEGORY(state) {
      state.productsByCategory = []
    },
    SET_PRODUCTS_BY_CATEGORY(state, products) {
      state.productsByCategory = [
        ...state.productsByCategory,
        ...products
      ]
    },
    SET_FAVORITES(state, favorites) {
      state.favoriteProducts = favorites
    },
    ADD_FAVORITE(state, id) {
      state.favorites = [...state.favorites, id]
    },
    REMOVE_FAVORITE(state, id) {
      state.favorites = state.favorites.filter(pId => pId !== id)
    },
    SET_PRODUCT_DETAIL_ID(state, id) {
      state.productDetailId = id
    },
    SET_PRODUCT_DETAIL(state, detail) {
      state.productDetail = detail
    },
    SET_SEARCH_SUGGESTIONS(state, suggestions) {
      state.searchSuggestions = suggestions
    },
    SET_SEARCH_RESULTS(state, products) {
      state.searchResults = [
        ...state.searchResults,
        ...products
      ]
    },
    CLEAR_SEARCH_RESULTS(state) {
      state.searchResults = []
    },
  },
  getters: {
    isProductFavorite: (state) => (id) => state.favorites.includes(id),
    favoritesCount: (state) => state.favorites.length
  },
  actions: {
    loadProductsByCategory({ commit }, params) {
      // const { categoryId, page, size, sort } = params;
      return new Promise((resolve, reject) => {
        Vue.axios.get('/api/v1/public/product-by-category', { params }).then((response) => {
          commit('SET_PRODUCTS_BY_CATEGORY', response.data.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    toggleFavorite({ commit, getters }, id) {
      if (getters.isProductFavorite(id)) {
        commit('REMOVE_FAVORITE', id)
      } else {
        commit('ADD_FAVORITE', id)
      }
    },
    loadFavorites({ commit, state }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('/api/v1/public/products', { 
          params: { id: state.favorites.join() }
        }).then((response) => {
          commit('SET_FAVORITES', response.data.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    setDetail({commit}, id) {
      commit('SET_PRODUCT_DETAIL_ID', id)
    },
    loadProductDetail({commit, state}) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(`/api/v1/public/product/${state.productDetailId}`).then((response) => {
          commit('SET_PRODUCT_DETAIL', response.data.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    loadSearchSuggestions({commit}, params) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(
          `/api/v1/public/product_search?search=${params.query}`, 
          { params }
        ).then((response) => {
          commit('SET_SEARCH_SUGGESTIONS', response.data.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    searchProducts({commit}, params) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(
          `/api/v1/public/product_all_search?search=${params.query}`, 
          { params }
        ).then((response) => {
          commit('SET_SEARCH_RESULTS', response.data.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    }
  },
}