<template>
  <div>
    <router-view />
    <Loading :loaded="loaded" />
    <product-detail-modal v-if="productDetailId" />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import ProductDetailModal from "@/components/ProductDetailModal";
import { mapState } from "vuex";

export default {
  components: {
    Loading,
    ProductDetailModal,
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    refreshProductDetail(url) {
      const productDetailId = url.split("#")[1];
      this.$store.dispatch("Products/setDetail", productDetailId ? productDetailId : null);
    },
  },
  created() {
    // custom hash navigation for modal
    this.refreshProductDetail(location.href);
    window.onpopstate = () => this.refreshProductDetail(location.href)
    this.$store.dispatch("Misc/loadCategories").finally(() => {
      this.loaded = true;
    });
  },
  computed: {
    ...mapState("Products", ["productDetailId"]),
    ...mapState(['showSidebar']),
  },
  watch: {
    '$route'() {
      if(this.$mobile.isSidebarOverlays() && this.showSidebar) {
        this.$store.commit('toggleSidebar')
      }
    }
  }
};
</script>