import axios from 'axios';
import store from '@/store';
import router from '@/router';

let http;

http = axios.create({
  baseURL: //process.env.NODE_ENV === 'development' ? '/' :
           process.env.VUE_APP_API_URL,
});

http.interceptors.request.use((request) => {
  // if (!('__silent' in request)) {
  //   store.commit('SET_LOADING', true);
  // }
  return request;
});

http.interceptors.response.use((response) => {
  // if (!('__silent' in response.config)) {
  //   store.commit('SET_LOADING', false);
  // }
  return response;
}, (error) => {
  // if (!('__silent' in error.config)) {
  //   store.commit('SET_LOADING', false);
  // }
  switch (error.response.status) {
    case 401:
      // auth error handler
      // router.push({ name: 'logout' })
      break;
    case 404:
      // not found error handler
      // router.push({ name: '404' })
      break;
    case 400:
      // bad request error handler
      break;
    default:
      // undefined error handler
      store.commit('ERROR', `error-${error.response.status}`);
      break;
  }
  return Promise.reject(error);
});

export default {
  install(Vue) {
    Vue.prototype.$axios = http;
    Vue.axios = http;
    Vue.prototype.$http = this;
    Vue.http = this;
  },
  setHeader(key, value) {
    http.defaults.headers.common[key] = value;
  },
  removeHeader(key) {
    delete http.defaults.headers.common[key];
  },
};
