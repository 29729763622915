export default {
  install(Vue) {
    Vue.prototype.$mobile = this;
    Vue.mobile = this;
  },
  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      .test(navigator.userAgent)
  },
  isPhone() {
    return window.screen.width < 640
  },
  isSidebarOverlays() {
    return window.screen.width < 1130
  }
}