<template>
  <div class="search" :class="{'focused': focused}">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.057 12.881a6.668 6.668 0 10-1.176 1.176.827.827 0 00.035.038l3.536 3.536a.833.833 0 001.178-1.179l-3.535-3.536a.827.827 0 00-.038-.035zm-1.73-7.625a5 5 0 11-7.071 7.071 5 5 0 017.071-7.07z" class="path" fill="red"/></svg>
    <input
      v-model="query"
      type="text" 
      :placeholder="$t('interface.search')"
      @focus="focus"
      @blur="blur"
      @keyup.enter="resultDetails"
    />
    <template v-if="isAuth">
    <div class="type">
      <select v-model="computedType" @change="search">
        <option :value="-1" selected>{{ $t('search.in_stock') }}</option>
        <option :value="0">{{ $t('search.available') }}</option>
        <option :value="1">{{ $t('search.all') }}</option>
      </select>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'chevron'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.229 5.172l-.943.942L8 10.828l4.714-4.714-.943-.942-3.77 3.77-3.772-3.77z" fill="red" class="path"/></svg>
    </div>
    </template>
    <transition name="fade-fast">
    <div class="suggestions-wrapper" v-if="query.length > 2 && focused">
      <template v-if="searchSuggestions.length">
        <ul>
          <li v-for="suggestion in suggestions" :key="suggestion.id">
            <a @click="() => productDetail(suggestion.id)">
              <span class="name">{{ suggestion.name }}</span>
              <span class="category">{{ suggestion.category }}</span>
            </a>
          </li>
        </ul>
        <div class="separator"></div>
      </template>
      <router-link :to="{
        name: 'search',
        params: { query, type }
      }">
        {{ $t('search.all_results', [query]) }}
      </router-link>
    </div>
    </transition>
  </div>
</template>

<script>
import { debounce } from "@/utils";
import { mapGetters, mapState } from 'vuex';

export default {
  name: "Search",
  data() {
    return {
      query: '',
      focused: false,
      loading: false
    }
  },
  methods: {
    focus() {
      this.focused = true
    },
    blur() {
      setTimeout(() => {
        this.focused = false
      }, 200)
    },
    search() {
      if (this.query.length < 3) return;
      this.loading = true;
      let params = { query: this.query, limit: 5 }
      if (this.isAuth) params['type'] = this.computedType
      this.$store.dispatch('Products/loadSearchSuggestions', params)
        .then(() => this.loading = false);
    },
    productDetail(id) {
      const currentLocation = location.href.split('#')[0]
      history.pushState({}, '', currentLocation+'#'+id)
      this.$store.dispatch('Products/setDetail', id)
    },
    resultDetails(e) {
      this.$router.push({
        name: 'search',
        params: { query: this.query }
      })
      e.target.blur();
      this.query = ''
    }
  },
  computed: {
    ...mapGetters('Misc', ['getCategoryById']),
    ...mapGetters('User', ['isAuth']),
    ...mapState('Products', ['searchSuggestions']),
    ...mapState('Misc', ['productFilterType']),
    computedType: {
      get() {
        return this.productFilterType
      },
      set(val) {
        this.$store.commit('Misc/SET_PRODUCT_FILTER_TYPE', val)
      }
    },
    suggestions() {
      return this.searchSuggestions.map(suggestion => {
        return {
          ...suggestion,
          category: suggestion.categories[0] ? 
                    this.getCategoryById(suggestion.categories[0])[`name${this.$t('k')}`] : ''
        }
      })
    }
  },
  watch: {
    query: debounce(function() {
      this.search()
    }, 100)
  }
};
</script>