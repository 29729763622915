<template>
  <Link class="category-card" :path="link">
    <div class="name">{{ data[`name${$t('k')}`] }}</div>
    <img :src="data.image" class="cover">
  </Link>
</template>

<script>
import Link from '@/components/Link.vue'

export default {
  name: "CategoryCard",
  components: { Link },
  props: {
    data: Object
  },
  computed: {
    link() {
      return {
        name: 'category',
        params: { id: this.data.id },
      }
    }
  }
}
</script>
