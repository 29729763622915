<template>
  <li :class="{'expanded': expanded}">
    <Link :path="link">
      <span 
        class="menu-item"
        :class="{'active': isActive}"
        @click="expanded = !expanded"
      >
        <img :src="data.iconDarkId" class="icon" v-if="showIcon && !!data.iconDarkId && !lightTheme" />
        <img :src="data.iconLightId" class="icon" v-else-if="showIcon && !!data.iconLightId && lightTheme" />
        <img src="@/assets/category-default.svg" class="icon" v-else-if="showIcon" />
        <span class="name">{{ data[`name${$t('k')}`] }}</span>
        <span class="contorls">
          <span class="badge" v-if="showBadge">{{ data.productCount }}</span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="data.children" v-bind:svg-inline="''" v-bind:class="'chevron'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.229 5.172l-.943.942L8 10.828l4.714-4.714-.943-.942-3.77 3.77-3.772-3.77z" fill="red" class="path"/></svg>
        </span>
      </span>
      <transition name="slide">
        <ul v-if="data.children && expanded">
          <menu-item
            v-for="category in data.children"
            :key="category.id"
            :data="category"
            show-badge
            :show-icon="false"
          />
        </ul>
      </transition>
    </Link>
  </li>
</template>

<script>
import Link from '@/components/Link.vue'
import {mapState} from "vuex";

const hasChildren = (children, id) => {
  if (!Array.isArray(children)) return false
  const has = children.map(item => item.id).includes(id)
  return has || children.map(item => hasChildren(item.children, id)).includes(true)
}

export default {
  name: 'MenuItem',
  props: {
    data: Object,
    showBadge: Boolean,
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  components: { Link },
  data() {
    return {
      expanded: hasChildren(this.data.children, this.$route.params.id),
      isActive: this.$route.params.id === this.data.id
    }
  },
  methods: {
    refresh() {
      this.isActive = this.$route.params.id === this.data.id
      this.expanded = hasChildren(this.data.children, this.$route.params.id)
    }
  },
  computed: {
    lightTheme() {
      return this.$store.getters.getLightTheme
    },
    link() {
      return this.data.children ? false : { 
        name: 'category',
        params: { id: this.data.id },
      };
    }
  },
  watch: {
    '$route'() {
      this.refresh()
    }
  }
};
</script>