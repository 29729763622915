<template>
	<div class="dropdown-wrapper"
			 :class="{'is-active': isDropdownActive}"
			 ref="dropdown"
			 v-on-clickaway="away">
		<div class="dropdown-trigger"
				 :class="[triggerClass]"
				 aria-haspopup="true"
				 @click="toggle">
			<slot name="trigger"></slot>
		</div>
		<transition name="fade-fast">
		<div class="dropdow-content"
				 :class="[contentClass, position]"
				 :style="{
						top: `${menuBounding.top + menuBounding.height + parseInt(topOffset)}px`,
						left: position === 'is-left' && `${menuBounding.left}px`,
						left: position === 'is-right' && `${menuBounding.right}px`,
						width: fitWidth ? `${menuBounding.width}px` : 'min-content',
						zIndex: zIndex + 999
					}"
				 v-if="isDropdownActive">
			<slot></slot>
		</div>
		</transition>
	</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: "DropdownWrapper",
	props: {
		triggerClass: String,
		contentClass: String,
		disabled: Boolean,
		position: {
      type: String,
      default: 'is-left'
    },
		fitWidth: Boolean,
		hideOnScroll: Boolean,
		topOffset: {
			type: [Number, String],
			default: 0
		}
	},
	mixins: [ clickaway ],
	data() {
		return {
			isDropdownActive: false,
			zIndex: 0,
			menuBounding: {
				top: 0,
				left: 0,
				width: 0,
				right: 0,
				height: 0
			}
		}
	},
	methods: {
		toggle() {
			if (this.disabled) return false
			this.isDropdownActive = !this.isDropdownActive

			if (this.isDropdownActive) {
				this.getZIndex()
				this.getBounding()
				window.addEventListener('scroll', this.onScroll, true)
			} else {
				window.removeEventListener('scroll', this.onScroll, true)
			}
		},
		onScroll(e) {
			if (this.hideOnScroll && e.target === document) {
				this.away()
			} else if (this.hideOnScroll && !e.target.classList.contains('dropdown')) {
				this.away()
			} else {
				this.getBounding()
			}
		},
		getZIndex() {
			if (!window.lastDropdownZIndex) {
				window.lastDropdownZIndex = 1;
				this.zIndex = 1;
			} else {
				window.lastDropdownZIndex += 1;
				this.zIndex = window.lastDropdownZIndex;
			}
		},
		getBounding() {
			this.menuBounding = this.$refs?.dropdown?.getBoundingClientRect()
		},
		away() {
			this.isDropdownActive = false;
		},
	}
};
</script>
