import Vue from "vue"

const initialState = {
  user: {
    role: null,
    token: null
  }
}

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    CLEAR_USER(state) {
      state.user = { ...initialState.user }
    }
  },
  getters: {
    isAuth(state) {
      return !!state.user.token
    }
  },
  actions: {
    auth({commit}, credentials) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('/api/v1/auth/access-token', credentials).then((response) => {
          if (response.data.role === 'ADMIN') {
            commit('SET_USER', response.data)
            resolve(response.data)
          } else {
            reject()
          }
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    logout({commit}) {
      return new Promise((resolve) => {
        commit('CLEAR_USER')
        resolve()
      })
    }
  },
}