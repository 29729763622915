<template>
  <div class="product-detail-modal">
    <div class="overlay" @click="close"></div>
    <div class="modal">
      <div class="photos-carousel">
        <template v-if="!loading">
          <Slider>
            <img 
              v-for="image, n in productDetail.images"
              :key="n"
              :src="image"
            />
          </Slider>
        </template>
      </div>

      <div class="info">
        <template v-if="!loading">
          <button class="close" @click="close">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.396 7.757a1 1 0 010 1.415l-2.982 2.981 2.676 2.675a1 1 0 11-1.415 1.415L12 13.567l-2.675 2.676a1 1 0 01-1.415-1.415l2.676-2.675-2.982-2.981A1 1 0 119.02 7.757L12 10.74l2.981-2.982a1 1 0 011.415 0z" fill="red" class="path"/><path fill-rule="evenodd" clip-rule="evenodd" d="M4 1a3 3 0 00-3 3v16a3 3 0 003 3h16a3 3 0 003-3V4a3 3 0 00-3-3H4zm16 2H4a1 1 0 00-1 1v16a1 1 0 001 1h16a1 1 0 001-1V4a1 1 0 00-1-1z" fill="red" class="path"/></svg>
          </button>
          <div class="info-wrapper">
            <div class="name">
              {{ productDetail.name }}
              <span>NT{{ productDetail.code }}</span>
            </div>
            <div class="sub-info">
              <div class="price">
                {{ price }} 
                <span>{{ $t('product.c_sum') }}</span>
                <span v-if="isAuth"> ({{ productDetail.price.usd }}$)</span>
              </div>
              <div class="out-of-stock" v-if="false">{{ $t('product.details') }}</div>
            </div>
            <div class="actions">
              <button class="favorite" @click="toggleFavorite">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!isFavourite" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_65_1243)"><path d="M16.5 3c-1.74 0-3.41.81-4.5 2.085C10.91 3.81 9.24 3 7.5 3 4.415 3 2 5.415 2 8.5c0 3.775 3.4 6.86 8.55 11.535L12 21.35l1.45-1.315C18.6 15.36 22 12.275 22 8.5 22 5.415 19.585 3 16.5 3zm-4.395 15.555L12 18.65l-.105-.095C7.14 14.24 4 11.39 4 8.5 4 6.505 5.505 5 7.5 5c1.54 0 3.04.995 3.565 2.36h1.865C13.46 5.995 14.96 5 16.5 5 18.495 5 20 6.505 20 8.5c0 2.89-3.14 5.74-7.895 10.055z" class="path"/></g></svg>
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:class="'icon active'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 14.012l-1.088-.986C3.05 9.52.5 7.206.5 4.375.5 2.061 2.311.25 4.625.25A4.5 4.5 0 018 1.814 4.5 4.5 0 0111.375.25c2.314 0 4.125 1.811 4.125 4.125 0 2.831-2.55 5.145-6.412 8.651L8 14.012z" fill="#ED705F"/></svg>
              </button>
              <button class="share" @click="share">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.5 12.06c-.57 0-1.08.225-1.47.577L6.683 9.525c.037-.173.067-.345.067-.525 0-.18-.03-.352-.067-.525l5.287-3.082A2.24 2.24 0 0013.5 6a2.247 2.247 0 002.25-2.25A2.247 2.247 0 0013.5 1.5a2.247 2.247 0 00-2.25 2.25c0 .18.03.353.068.525L6.03 7.357A2.24 2.24 0 004.5 6.75 2.247 2.247 0 002.25 9a2.247 2.247 0 002.25 2.25 2.24 2.24 0 001.53-.607l5.34 3.12c-.037.157-.06.322-.06.487 0 1.207.982 2.19 2.19 2.19 1.207 0 2.19-.983 2.19-2.19 0-1.207-.982-2.19-2.19-2.19zm0-9.06c.412 0 .75.337.75.75 0 .412-.338.75-.75.75a.752.752 0 01-.75-.75c0-.413.338-.75.75-.75zm-9 6.75A.752.752 0 013.75 9c0-.412.338-.75.75-.75s.75.338.75.75-.338.75-.75.75zm9 5.265a.752.752 0 01-.75-.75c0-.413.338-.75.75-.75s.75.337.75.75c0 .412-.338.75-.75.75z" fill="red" class="path"/></svg>
              </button>
            </div>
          </div>
          <div class="description-wrapper">
            <div class="label">{{ $t('product.specifications') }}</div>
            <div class="description">
              <div class="scrolls">
                <div v-html="productDetail.description"></div>
              </div>
            </div>
          </div>    
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { numberWithSeparator } from '@/utils'
import { VueAgile as Slider } from 'vue-agile'

export default {
  data() {
    return {
      loading: true
    }
  },
  components: {
    Slider
  },
  methods: {
    close() {
      history.pushState({}, '', location.href.split('#')[0])
      this.$store.dispatch('Products/setDetail', null)
    },
    toggleFavorite() {
      this.$store.dispatch('Products/toggleFavorite', this.productDetailId)
    },
    share() {
      if (navigator.share)
        navigator.share({
          title: this.productDetail.name,
          url: location.href
        })
    },
    loadData() {
      this.loading = true
      this.$store.dispatch('Products/loadProductDetail').then(() => this.loading = false)
    }
  },
  mounted() {
    this.loadData()
  },
  computed: {
    ...mapState('Products', ['productDetailId', 'productDetail']),
    ...mapGetters('User', ['isAuth']),
    price() {
      return numberWithSeparator(this.productDetail.price.uzs, ' ')
    },
    isFavourite() {
      return this.$store.getters['Products/isProductFavorite'](this.productDetailId)
    }
  }
}
</script>