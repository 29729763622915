<template>
  <base-layout>
    <div class="content">
      <div class="home-page-grid">
        <category-card
          v-for="category in categories"
          :key="category.id"
          :data="category"
          class="category"
        />
        <Link :path="{name: 'sitemap'}" class="category-card more">
          <div class="name">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.5 17.454H20v-7.5h-7.5v2.5h3.232L9.07 19.116l1.768 1.768 6.662-6.662v3.232z" class="path" fill="red"/><path fill-rule="evenodd" clip-rule="evenodd" d="M28.75 23.75a5 5 0 01-5 5H6.25a5 5 0 01-5-5V6.25a5 5 0 015-5h17.5a5 5 0 015 5v17.5zm-5 2.5H6.25a2.5 2.5 0 01-2.5-2.5V6.25a2.5 2.5 0 012.5-2.5h17.5a2.5 2.5 0 012.5 2.5v17.5a2.5 2.5 0 01-2.5 2.5z" class="path" fill="red"/></svg>
            <span>{{ $t('home.all_categories') }}</span>
          </div>
        </Link>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/layout/BaseLayout.vue"
import CategoryCard from "@/components/CategoryCard.vue"
import Link from "@/components/Link.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'HomeView',
  components: { BaseLayout, CategoryCard, Link },
  computed: {
    ...mapGetters('Misc', ['getMainPageCategories']),
    categories() {
      return this.getMainPageCategories.slice(0, 8)
    }
  }
}
</script>
