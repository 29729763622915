import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Misc from './modules/misc'
import Products from './modules/products'
import User from './modules/user'
import mobile from '@/mobile'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Misc,
    Products,
    User,
  },
  state: {
    showSidebar: !mobile.isMobile(),
    lightTheme: false
  },
  getters: {
    getLightTheme(state) {
      return state.lightTheme
    }
  },
  mutations: {
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar
    },
    toggleLightTheme(state) {
      state.lightTheme = !state.lightTheme
    }
  },
  actions: {
  },
  plugins: [createPersistedState({
    key: 'NANOTECHCATALOGUE',
    storage: window.localStorage
  })]
})
