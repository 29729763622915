import Vue from 'vue';

const STORAGE_KEY = 'SMART_MARKETPLACE_TOKEN';

export default {
  install(_Vue) {
    const TOKEN = window.localStorage.getItem(STORAGE_KEY);
    if (TOKEN) {
      this.bind(TOKEN);
    }
    _Vue.prototype.$auth = this;
    _Vue.auth = this;
  },
  bind(token) {
    Vue.http.setHeader('Authorization', `Bearer ${token}`);
    window.localStorage.setItem(STORAGE_KEY, token);
  },
  unbind() {
    Vue.http.removeHeader('Authorization');
    window.localStorage.removeItem(STORAGE_KEY);
    window.location.reload()
  },
  isAuth() {
    return window.localStorage.getItem(STORAGE_KEY);
  },
};
