<template>
  <router-link class="favorite" :to="{name: 'favorites'}">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_65_1243)"><path d="M16.5 3c-1.74 0-3.41.81-4.5 2.085C10.91 3.81 9.24 3 7.5 3 4.415 3 2 5.415 2 8.5c0 3.775 3.4 6.86 8.55 11.535L12 21.35l1.45-1.315C18.6 15.36 22 12.275 22 8.5 22 5.415 19.585 3 16.5 3zm-4.395 15.555L12 18.65l-.105-.095C7.14 14.24 4 11.39 4 8.5 4 6.505 5.505 5 7.5 5c1.54 0 3.04.995 3.565 2.36h1.865C13.46 5.995 14.96 5 16.5 5 18.495 5 20 6.505 20 8.5c0 2.89-3.14 5.74-7.895 10.055z" class="path"/></g></svg>
    <div class="badge" v-if="favoritesCount">{{ favoritesCount }}</div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "FavoriteButton",
  computed: {
    ...mapGetters('Products', ['favoritesCount'])
  }
}
</script>