<template>
  <dropdown-wrapper position="is-right" top-offset="12" ref="dropdown">
    <template #trigger>
      <div class="language-picker">
        <img :src="getImgUrl(`lang/${currentLocale}.png`)" class="flag" />
        <span>{{ $t('code') }}</span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'chevron'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.229 5.172l-.943.942L8 10.828l4.714-4.714-.943-.942-3.77 3.77-3.772-3.77z" fill="red" class="path"/></svg>
      </div>
    </template>
    <div class="dropdown language">
      <ul>
        <li
          v-for="item in locales" 
          :key="item.key" 
        >
          <a 
            :class="{active: currentLocale === item.key}"
            @click="() => setLocale(item.key)"
          >
            <img :src="getImgUrl(`lang/${item.key}.png`)" alt="" />
            <span>{{ item.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </dropdown-wrapper>
</template>

<script>
import DropdownWrapper from '@/components/Dropdown.vue'

export default {
  name: "LanguagePicker",
  components: { DropdownWrapper },
  data() {
    return {
      locales: [
        {name: 'Русский', key: 'ru'},
        {name: 'Узбекча', key: 'uz'}
      ]
    }
  },
  methods: {
    setLocale(locale) {
      this.$refs['dropdown'].away()
      this.$locale.change(locale)
    },
    getImgUrl(path) {
      return require(`../../assets/${path}`)
    }
  },
  computed: {
    currentLocale() {
      return this.$locale.current()
    }
  }
}
</script>