<template>
  <div class="breadcrumbs">
    <ul>
      <li
        v-for="(item,n) in items"
        :key="n"
      >
        <a @click="navigate(item.path)">
          {{ item.label || $t(`routes.${item.name}`) }}
        </a>
      </li>
    </ul>
    <a @click="back">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_32_237)"><path d="M9 5.818l1.06 1.06L7.94 9l2.12 2.121L9 12.182 5.818 9 9 5.818z" class="path" fill="red"/><path fill-rule="evenodd" clip-rule="evenodd" d="M9 .75a8.25 8.25 0 110 16.5A8.25 8.25 0 019 .75zM2.25 9a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0z" class="path" fill="red"/></g></svg>
      <span>{{ $t('interface.back') }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbsMenu",
  props: {
    items: Array
  },
  methods: {
    navigate(path) {
      if (typeof path === "string") {
        this.$router.push({name: path})
      } else if (typeof path === "object") {
        this.$router.push(path)
      }
    },
    back() {
      window.history.back(1)
    }
  }
}
</script>