<template>
  <div 
    class="theme-switcher"
    :class="{'light': lightTheme}"
    @click="toggle"
  >
    <span>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="lightTheme" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.132 1.168a5.833 5.833 0 105.503 4.319 8.68 8.68 0 01-1.017.838c-.789.553-1.595.911-2.325 1.074C8.18 7.646 7.417 7.417 7 7c-.417-.417-.646-1.18-.399-2.293.163-.73.521-1.536 1.074-2.325.242-.345.521-.686.838-1.017a5.854 5.854 0 00-1.381-.197zM10.3 10.3a4.646 4.646 0 001.313-2.59c-2.07 1.194-4.24 1.312-5.438.115-1.197-1.197-1.08-3.369.114-5.437A4.667 4.667 0 1010.3 10.3z" fill="red" class="path"/></svg>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 9.333a2.333 2.333 0 110-4.666 2.333 2.333 0 010 4.666zM7 10.5a3.5 3.5 0 110-7 3.5 3.5 0 010 7zM7.583 0H6.417v2.37a4.712 4.712 0 011.166 0V0zm2.279 3.313l1.675-1.675.825.825-1.675 1.675a4.693 4.693 0 00-.825-.825zm1.769 3.104H14v1.166h-2.37a4.718 4.718 0 000-1.166zm-.944 3.445l1.675 1.675-.825.825-1.675-1.675c.308-.24.585-.517.825-.825zM7.583 11.63V14H6.417v-2.37a4.718 4.718 0 001.166 0zm-3.445-.944l-1.675 1.675-.825-.825 1.675-1.675c.24.308.517.585.825.825zM2.37 7.583H0V6.417h2.37a4.712 4.712 0 000 1.166zm.944-3.445L1.638 2.463l.825-.825 1.675 1.675c-.308.24-.585.517-.825.825z" fill="red" class="path"/></svg>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "ThemeSwitcher",
  methods: {
    toggle() {
      this.$store.commit('toggleLightTheme')
      this.refresh()
    },
    refresh() {
      const theme = this.lightTheme ? 'light' : 'dark';
      document.documentElement.setAttribute('data-theme', theme);
    },
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapState(['lightTheme'])
  }
}
</script>